import React, { useState, useMemo } from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia, Chip,
    Grid,
    IconButton,
    Modal,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { Logger } from "@aws-amplify/core";
import DataTable from 'react-data-table-component';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { saveAs } from 'file-saver';
import { format } from 'date-fns';

const logger = new Logger("FindocCard");

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
};

const imageModalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1300, // Ensure it's above most other components
};

const isValidDate = (date) => {
    return !isNaN(new Date(date).getTime());
};

const FindocDetailsCard = ({ findoc }) => {
    const [open, setOpen] = useState(false);
    const [imageOpen, setImageOpen] = useState(false);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const toggleImageModal = () => setImageOpen(!imageOpen);

    const handleDownloadCSV = () => {
        let tsvContent = "ID\tInstitution Name\tStatement Date\tBalance\tInstitution Address\tDate\tDescription\tAmount\n";

        // Adding receipt metadata
        findoc.lineItems.forEach(lineItem => {
            tsvContent += `${findoc._id}\t${findoc.institutionName}\t${findoc.statementDate}\t${findoc.balance}\t${findoc.institutionAddress}\t${lineItem.transactionDate || ''}\t${lineItem.description || ''}\t${lineItem.amount || ''}\n`;
        });

        const blob = new Blob([tsvContent], { type: 'text/tsv;charset=utf-8;' });
        saveAs(blob, "findoc_details.tsv");
    };

    const imageUrl = `https://cdn.assetsmaven.com/${encodeURIComponent(findoc.imageLocation)}`;

    const columns = useMemo(() => [
        {
            name: 'Date',
            selector: 'transactionDate',
            sortable: true,
            cell: row => {
                const date = row.transactionDate;
                if (!isValidDate(date)) {
                    return 'Invalid Date';
                }
                return format(new Date(date), 'MM/dd/yyyy');
            }
        },
        { name: 'Category', selector: 'category', sortable: true },
        { name: 'Description', selector: 'description', sortable: true },
        { name: 'Amount', selector: 'amount', sortable: true },
    ], []);

    const data = useMemo(() => {
        return findoc.lineItems.map(item => ({
            ...item,
            findocId: findoc._id
        }));
    }, [findoc]);

    const renderTable = () => {
        if (matches) {
            return findoc.lineItems.map((lineItem, index) => (
                <Card key={index} variant="outlined" sx={{ mb: 2, p: 2 }}>
                    <Typography variant="body1" sx={{ mb: 1 }}>
                        <strong>Date:</strong> {isValidDate(lineItem.transactionDate) ? format(new Date(lineItem.transactionDate), 'MM/dd/yyyy') : 'N/A'}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}><strong>Category:</strong> <Chip label={lineItem.category} color="primary" size="small" /></Typography>
                    <Typography variant="body1" sx={{ mb: 1 }}><strong>Description:</strong> {lineItem.description}</Typography>
                    <Typography variant="body1"><strong>Amount:</strong> {lineItem.amount}</Typography>
                </Card>
            ));
        } else {
            return (
                <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        responsive
                        style={{ width: '100%' }}
                    />
            );
        }
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, width: '100%' }}>
            <Card sx={{
                width: '90%',
                margin: 'auto',
                marginTop: 2,
                marginBottom: 2,
                boxShadow: '1px 1px 5px rgba(0,0,0,0.2)',
                borderRadius: '8px',
                border: '1px solid #e0e0e0',
                '&:hover': {
                    boxShadow: '1px 2px 10px rgba(0,0,0,0.3)',
                }
            }}>
                {findoc.imageLocation && (
                    <CardMedia
                        component="img"
                        height="194"
                        image={imageUrl}
                        alt="Findoc Image"
                        onClick={toggleImageModal}
                        style={{ cursor: 'pointer' }}
                    />
                )}
                <CardContent>
                    <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 'medium', color: '#1976d2' }}>
                        {findoc.institutionName}
                    </Typography>
                    <Typography sx={{ mb: 1.5, color: '#555' }} color="text.secondary">
                        Date Created: {isValidDate(findoc.meta.createdDate) ? format(new Date(findoc.meta.createdDate), 'MM/dd/yyyy') : 'N/A'}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2, fontWeight: 'bold' }}>
                        Balance: {findoc.balance}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Address: {findoc.institutionAddress}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Statement Date: {isValidDate(findoc.statementDate) ? format(new Date(findoc.statementDate), 'MM/dd/yyyy') : 'N/A'}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Statement Date Range: {findoc.statementDateRange}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Statement Year: {findoc.statementYear}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Payment Date: {isValidDate(findoc.paymentDate) ? format(new Date(findoc.paymentDate), 'MM/dd/yyyy') : 'N/A'}
                    </Typography>
                    <Grid container direction="column" alignItems="stretch" spacing={1}>
                        <Grid item xs={12}>
                            {renderTable()}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                startIcon={<FileDownloadIcon />}
                                variant="outlined"
                                color="primary"
                                onClick={handleDownloadCSV}
                                fullWidth
                            >
                                Download TSV
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...modalStyle, overflow: 'hidden', maxHeight: '90vh' }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Line Items ({findoc.lineItems.length})
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Box sx={{ overflowY: 'auto', maxHeight: '80vh' }}>
                        {renderTable()}
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={imageOpen}
                onClose={toggleImageModal}
                aria-labelledby="image-modal-title"
                aria-describedby="image-modal-description"
            >
                <Box sx={imageModalStyle}>
                    <IconButton
                        onClick={toggleImageModal}
                        color="inherit"
                        sx={{ position: 'fixed', top: 8, right: 8, color: 'white' }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <img src={imageUrl} alt="Findoc" style={{ maxHeight: '90%', maxWidth: '90%' }} />
                </Box>
            </Modal>
        </Box>
    );
};

export default FindocDetailsCard;
