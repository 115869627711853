import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Pagination,
    TextField,
    Typography,
    Menu,
    MenuItem,
    Divider
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import FindocCard from "./findoc-card";
import { FindocsService } from "../../services/dao/findocs-service";
import { Logger } from "@aws-amplify/core";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import { PlaidService } from "../../services/dao/plaid-service";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { usePlaidLink } from "react-plaid-link";
import { saveAs } from 'file-saver';
import { SearchService } from "../../services/dao/search-service";
import SearchResult from "./components/findoc-search-result";
import FindocsInstructions from "./findocs-instructions";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'auto',
        },
    },
    controlPanel: {
        backgroundColor: "#eceef3",
        width: '25%',
        padding: theme.spacing(3),
        boxSizing: 'border-box',
        position: 'fixed',
        height: '100vh',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '100%',
            height: 'auto',
            overflowY: 'visible',
        },
    },
    findocsPanel: {
        flexGrow: 1,
        marginLeft: '25%',
        padding: theme.spacing(3),
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            padding: theme.spacing(2),
        },
    },
    header: {
        margin: 4,
    },
    downloadButton: {
        marginBottom: theme.spacing(2),
    },
    searchInput: {
        marginBottom: theme.spacing(3),
    },
    pagination: {
        marginTop: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
    },
    searchBox: {
        display: 'flex',
        alignItems: 'center',
    },
    resetButton: {
        marginLeft: theme.spacing(2),
        height: '56px', // Adjust height to match the TextField
    },
}));

const logger = new Logger("FindocsListScreen");
const findocsService = new FindocsService();
const searchService = new SearchService();

import PlaidConnectButton from './components/plaid-connect-button';

export function FindocsListScreen(props) {
    const classes = useStyles();
    const [findocsList, setFindocsList] = useState([]);
    const [filteredFindocs, setFilteredFindocs] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [plaidLinkToken, setPlaidLinkToken] = useState(null);
    const [selectedInstitutionType, setSelectedInstitutionType] = useState(null);
    
    const plaidService = PlaidService.getInstance();
    const [itemsPerPage, setItemsPerPage] = useState(20);

    useEffect(() => {
        if (searchQuery == null || searchQuery === "") {
            setFilteredFindocs(findocsList);
            setSearchResults([]);
            return;
        }

        const searchEverything = async () => {
            let response = await searchService.searchFindocs(searchQuery);
            logger.info("Searched...got " + JSON.stringify(response.body));
            if (response && !response.error && response.body && response.body.length > 0) {
                setSearchResults(response.body);
                setFilteredFindocs(response.body);
            } else {
                setSearchResults([]);
                setFilteredFindocs([]);
            }
        };
        if (searchQuery && searchQuery.length > 2) searchEverything();
    }, [searchQuery]);

    useEffect(() => {
        const getMyFindocs = async () => {
            let response = await findocsService.getMyFindocs();
            if (response && !response.error) {
                setFindocsList(response.body);
                setFilteredFindocs(response.body);
            }
        };
        getMyFindocs();
    }, []);

    const handleDownloadDisplayedFindocs = async () => {
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += "Findoc ID\tInstitution Name\tStatement Date\tBalance\tInstitution Address\tDate\tDescription\tAmount\n";

        const fetchDetailsPromises = filteredFindocs.map(async (findoc) => {
            return findoc.lineItems.map(lineItem => {
                const amount = lineItem.amount ? parseFloat(lineItem.amount).toFixed(2) : '';
                return `${findoc._id}\t${findoc.institutionName}\t${findoc.statementDate}\t${findoc.balance}\t${findoc.institutionAddress}\t${lineItem.date || ''}\t${lineItem.description || ''}\t${amount}`;
            }).join("\n");
        });

        const allFindocDetails = await Promise.all(fetchDetailsPromises);
        csvContent += allFindocDetails.join("\n");

        const blob = new Blob([csvContent], { type: 'text/tsv;charset=utf-8;' });
        saveAs(blob, "displayed_findocs_details.tsv");
    };

    const handleReset = () => {
        setSearchQuery("");
        setSearchResults([]);
        setFilteredFindocs(findocsList);
    };

    const indexOfLastFindoc = currentPage * itemsPerPage;
    const indexOfFirstFindoc = indexOfLastFindoc - itemsPerPage;
    const currentFindocs = filteredFindocs.slice(indexOfFirstFindoc, indexOfLastFindoc);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const totalPages = Math.ceil(filteredFindocs.length / itemsPerPage);

    return (
        <Box className={classes.root}>
            <Box className={classes.controlPanel}>
                <Box sx={{ mb: 2 }}>
                    <PlaidConnectButton />
                </Box>
                <Typography variant="h5" component="h5" className={classes.header} color="textPrimary">
                    Uploaded Findocs
                </Typography>

                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownloadIcon />}
                    onClick={handleDownloadDisplayedFindocs}
                    className={classes.downloadButton}
                >
                    CSV ({filteredFindocs.length} findocs)
                </Button>
                <Typography variant="subtitle1" component="p" color="textSecondary">
                    Displayed Findocs: {filteredFindocs.length}
                </Typography>
            </Box>

            {!findocsList || findocsList.length === 0 ? (
                <Box className={classes.findocsPanel}>
                    <FindocsInstructions />
                </Box>
            ) : (
                <Box className={classes.findocsPanel}>
                    <Box className={classes.searchBox}>
                        <TextField
                            className={classes.searchInput}
                            label="Search Findocs"
                            variant="outlined"
                            fullWidth
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                endAdornment: searchQuery && (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleReset}>
                                            <CloseIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    {searchResults.length > 0 ? (
                        searchResults.map((result, index) => (
                            <SearchResult key={index} data={result} searchQuery={searchQuery} />
                        ))
                    ) : (
                        <>
                            {totalPages > 1 && (
                                <Pagination
                                    className={classes.pagination}
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                    color="primary"
                                />
                            )}
                            <Grid container spacing={4} justifyContent="flex-start">
                                {currentFindocs.map((findoc, index) => (
                                    <Grid item key={index} xs={12} sm={6} md={4} lg={4}>
                                        <FindocCard findoc={findoc} />
                                    </Grid>
                                ))}
                            </Grid>
                            {totalPages > 1 && (
                                <Pagination
                                    className={classes.pagination}
                                    count={totalPages}
                                    page={currentPage}
                                    onChange={handleChangePage}
                                    color="primary"
                                />
                            )}
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
}
