import React, { useEffect, useState } from 'react';
import './auth-styles.scss';
import { ModernCard } from '../../components/ui/ModernCard';
import { ButtonWithEffects } from '../../components/ui/ButtonWithEffects';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useHistory } from 'react-router-dom';
import { Hub, Logger } from '@aws-amplify/core';
import { AuthService } from "./service/auth-service";
import { config } from "./service/constants";
import { Alert, Box, InputAdornment } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { motion } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%)',
    },
    reasonsContainer: {
        display: 'flex',
        alignItems: 'center',
        minHeight: '100vh',
        padding: theme.spacing(2),
    },
    reasons: {
        height: '100%',
        maxHeight: '600px',
        padding: theme.spacing(6),
        background: 'linear-gradient(145deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.7) 100%)',
        backdropFilter: 'blur(10px)',
        borderRadius: '24px',
        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    reasonsHeader: {
        marginBottom: theme.spacing(4),
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: '2rem',
    },
    reasonsList: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        paddingLeft: 0,
        listStyle: 'none',
        '& li': {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(1.5),
            background: 'rgba(255, 255, 255, 0.5)',
            borderRadius: '12px',
            transition: 'all 0.3s ease',
            '&:hover': {
                transform: 'translateX(8px)',
                background: 'rgba(255, 255, 255, 0.8)',
            }
        },
    },
    reasonIcon: {
        color: theme.palette.primary.main,
        marginRight: theme.spacing(2),
    },
    paperContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        height: '100%',
    },
    authbox: {
        width: '100%',
        maxWidth: '450px',
        padding: theme.spacing(6),
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '24px',
        boxShadow: '0 12px 40px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(10px)',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'translateY(-4px)',
        },
    },
    logoContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
    },
    logo: {
        width: '190px',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            transition: 'all 0.3s ease',
            '&:hover': {
                transform: 'translateY(-2px)',
            },
            '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.1)',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(1.5),
        borderRadius: '12px',
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
        transition: 'all 0.3s ease',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: `0 6px 20px ${theme.palette.primary.light}40`,
        },
    },
    authLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        transition: 'all 0.3s ease',
        '&:hover': {
            color: theme.palette.primary.dark,
            textDecoration: 'underline',
        },
    },
}));

const ReasonItem = ({ text }) => {
    return (
        <motion.li
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
        >
            <CheckCircleOutlineIcon className={useStyles().reasonIcon} />
            {text}
        </motion.li>
    );
};

export function RegisterForm() {
    const classes = useStyles();
    const logger = new Logger("RegisterForm");
    const history = useHistory();

    const [errorMessage, setErrorMessage] = useState("");
    const [userNotConfirmed, setUserNotConfirmed] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
    const [password, setPassword] = useState('');

    useEffect(() => {
        Hub.listen(AuthService.CHANNEL, onHubCapsule, 'MyListener');

        return function cleanup() {
            logger.info("Removing HUB subscription to " + AuthService.CHANNEL);
            Hub.remove(AuthService.CHANNEL, onHubCapsule);
        };
    }, []);

    const onHubCapsule = async (capsule) => {
        const { channel, payload } = capsule;
        if (channel === AuthService.CHANNEL && payload.event === AuthService.AUTH_EVENTS.REGISTER) {
            if (!payload.success) {
                setErrorMessage(payload.message);
                logger.info("Couldn't register: " + payload.message);
            } else {
                history.push(config.endpointAfterRegistration);
            }
        }
    };

    const onFinish = e => {
        e.preventDefault();
        localStorage.setItem('savedEmail', email);
        AuthService.register(email, password);
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid container direction="row" className={classes.reasonsContainer}>
                <Grid item xs={12} sm={6} md={6}>
                    <motion.div 
                        className={classes.reasons}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6 }}
                    >
                        <Typography component="h1" variant="h4" className={classes.reasonsHeader}>
                            What's included
                        </Typography>
                        <ul className={classes.reasonsList}>
                            <ReasonItem text="Credit Card & Bank Statements Aggregation" />
                            <ReasonItem text="Transaction Tracking" />
                            <ReasonItem text="Receipt Tracking" />
                            <ReasonItem text="Detailed Spending Analysis" />
                            <ReasonItem text="Search capabilities" />
                            <ReasonItem text="Subscription Insights" />
                            <ReasonItem text="Non-intrusive data collection" />
                        </ul>
                    </motion.div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.paperContainer}>
                    <motion.div 
                        className={classes.authbox}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.6, delay: 0.2 }}
                    >
                        <div className={classes.logoContainer}>
                            <img 
                                src={window.location.origin + '/logos/logo-md.png'} 
                                className={classes.logo} 
                                alt="Logo" 
                            />
                        </div>
                        <Typography component="h1" variant="h4" align="center" gutterBottom>
                            Create Account
                        </Typography>
                        {errorMessage && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                            >
                                <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>
                            </motion.div>
                        )}
                        <form className={classes.form} noValidate onSubmit={onFinish}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                className={classes.textField}
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onInput={e => setEmail(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                className={classes.textField}
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <ButtonWithEffects
                                type="submit"
                                fullWidth
                                className={classes.submit}
                                variant="contained"
                                color="primary"
                            >
                                Create Account
                            </ButtonWithEffects>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Link to="/forgotpassword1" className={classes.authLink}>
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to="/login" className={classes.authLink}>
                                        Already registered? Login
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </motion.div>
                </Grid>
            </Grid>
        </Grid>
    );
}
