import React from 'react';
import {DropDownNavBarMenu} from "./drop-down-navbar-menu";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import {Link, useHistory} from "react-router-dom";
import {Box} from "@mui/material";
import Hidden from "@mui/material/Hidden";
import {AuthService} from "../../../screens/auth/service/auth-service";
import {Search} from "@mui/icons-material";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    appBar: {
        borderBottom: 'none',
        background: theme.palette.background.gradient,
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(10px)',
        position: 'fixed',
        top: 0,
        left: 0,
        transition: 'all 0.3s ease-in-out',
        right: 0,
        zIndex: theme.zIndex.appBar,
    },
    toolbar: {
        flexWrap: 'wrap',
        transition: 'all 0.3s ease-in-out',
        '& .MuiButton-root': {
            transition: 'transform 0.2s ease-in-out',
            '&:hover': {
                transform: 'translateY(-2px)'
            }
        }
    },
    toolbarTitle: {
        flexGrow: 1,
        background: 'linear-gradient(45deg, #3B82F6, #8B5CF6)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        fontWeight: 600,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    content: {
        paddingTop: theme.spacing(8), // Add top padding to avoid overlap with the top navbar
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(9), // Increase top padding for larger screens if needed
        },
    },
    heroContent: {
        padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
}));

export function MyTopAppBar(props) {
    const classes = useStyles();
    const loggedIn = props.loggedIn;
    const user = props.loggedInUser;
    const history = useHistory();

    const logout = async (event) => {
        await AuthService.signOut();
        history.push("/login");
    }

    const addArtifact = async (event) => {
        history.push("/uploads")
    }

    return (
        <Box flexDirection="row" width={1}>
            <CssBaseline/>
            <AppBar position="fixed" color="default" elevation={0} className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
                        <Link to={"/"}>
                            <img src={window.location.origin + '/logos/logo-md.png'}
                                 style={{width: "190px"}} alt={""}/>
                        </Link>
                    </Typography>

                    <Hidden smDown>
                        {loggedIn &&
                            <Link to={"/dashboard"}>
                                <Button startIcon={<AccountBalanceIcon style={{ color: "#3f51b5" }} />}>Dashboard</Button>
                            </Link>
                        }

                        {loggedIn &&
                            <Link to={"/findocs"}>
                                <Button startIcon={<AccountBalanceIcon style={{ color: "#4caf50" }} />}>Financial Docs</Button>
                            </Link>
                        }
                        {loggedIn &&
                            <Link to={"/receipts"}>
                                <Button startIcon={<ReceiptLongIcon style={{ color: "#f44336" }} />}>Receipts</Button>
                            </Link>
                        }
                    </Hidden>

                    {props.loggedIn &&
                        <DropDownNavBarMenu loggedIn={loggedIn} logoutFunction={logout} loggedInUser={user}/>
                    }

                    {!loggedIn && <Link to={"/login"}>
                        <Button variant={"outlined"} size={"small"} color="secondary">
                            Login
                        </Button>
                    </Link>}
                </Toolbar>
            </AppBar>
            <Toolbar /> {/* This Toolbar component adds space below the fixed AppBar */}
        </Box>
    );
}

export default MyTopAppBar;
