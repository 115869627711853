import React, {useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Logger} from "@aws-amplify/core";
import {FindocsService} from "../../services/dao/findocs-service";
import {useHistory, useParams} from "react-router-dom";
import {Auth} from "aws-amplify";
import Box from "@mui/material/Box";
import {Button, Container, Paper, useTheme, Typography} from "@mui/material";
import { alpha } from '@mui/material/styles';
import ArrowBack from '@mui/icons-material/ArrowBack';
import FindocDetailsCard from "./components/findoc-details-card";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        padding: theme.spacing(3),
        background: theme.palette.background.gradient,
        minHeight: '100vh',
        overflowX: 'hidden'
    },
    pageTitle: {
        fontWeight: 600,
        background: theme.palette.primary.gradient,
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        marginBottom: theme.spacing(1)
    },
    backButton: {
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '12px',
        padding: '8px 20px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
        backdropFilter: 'blur(8px)',
        '&:hover': {
            background: `linear-gradient(45deg, ${alpha(theme.palette.primary.main, 0.08)} 0%, ${alpha(theme.palette.secondary.main, 0.08)} 100%)`,
            transform: 'translateX(-4px)',
            boxShadow: '0 6px 16px rgba(0, 0, 0, 0.08)'
        },
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    },
    contentWrapper: {
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '24px',
        boxShadow: '0 12px 40px rgba(0, 0, 0, 0.08)',
        backdropFilter: 'blur(12px)',
        padding: theme.spacing(4),
        marginTop: theme.spacing(3),
        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 20px 48px rgba(0, 0, 0, 0.12)',
        },
    },
    loadingState: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '200px',
        background: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '16px',
        backdropFilter: 'blur(8px)'
    }
}));
const logger = new Logger("FindocDetailsScreen");
const findocsService = new FindocsService();

export default function FindocDetailsScreen() {
    const classes = useStyles();
    const [findocDetails, setFindocDetails] = useState(null);
    const history = useHistory();
    const theme = useTheme();

    let {findocId} = useParams();

    logger.info("FindocID:   " + findocId)
    useEffect(() => {

        const load = async () => {
            let findocInfo
                = await findocsService.getFindoc(findocId)

            let session = await Auth.currentSession();
            logger.debug("sub: " + session["accessToken"]["payload"]["sub"])

            setFindocDetails(findocInfo.body);
        }

        load();

    }, [findocId])

    const handleNavigateBack = () => {
        history.goBack();
    };

    return (
        <div className={classes.root}>
            <Box sx={{ marginBottom: 2 }}>
                <Button 
                    variant="contained"
                    startIcon={<ArrowBack />}
                    onClick={handleNavigateBack}
                    className={classes.backButton}
                    disableElevation
                >
                    Back to Documents
                </Button>
                    <Typography 
                        variant="h4" 
                        component="h1"
                        className={classes.pageTitle}
                    >
                        Document Details
                    </Typography>
                </Box>
            
            {findocDetails ? (
                <Box sx={{ width: '100%' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/findocs')}
                        sx={{ mb: 2 }}
                    >
                        Back to Documents
                    </Button>
                    <FindocDetailsCard findoc={findocDetails}/>
                </Box>
            ) : (
                <Box className={classes.contentWrapper}>
                    <div className={classes.loadingState}>
                        <Typography variant="h6" color="textSecondary">
                            Loading document details...
                        </Typography>
                    </div>
                </Box>
            )}
        </div>
    );
}
