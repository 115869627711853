import React from 'react';
import { ThemeProvider } from './theme/ThemeProvider';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import { StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import awsConfig from './configs/aws-configs';
import { FullLayout } from "./components/layout/full-layout";
import { AuthScreen } from "./screens/auth/auth-screen";
import { config } from "./services/constants";
import theme from './theme';

Amplify.configure(awsConfig);
Amplify.Logger.LOG_LEVEL = config.logLevel;

const getIsMobile = () => window.matchMedia('(max-width: 768px)').matches;

export function App() {
    const [isMobile, setIsMobile] = React.useState(getIsMobile());

    React.useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)');
        const handler = (e) => setIsMobile(e.matches);
        
        // Modern browsers
        if (mediaQuery.addEventListener) {
            mediaQuery.addEventListener('change', handler);
            return () => mediaQuery.removeEventListener('change', handler);
        }
        
        // Older browsers
        mediaQuery.addListener(handler);
        return () => mediaQuery.removeListener(handler);
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider>
                <Router>
                    <Switch>
                        {/* Authentication */}
                        <Route path={"/login"} component={AuthScreen}/>
                        <Route path={"/register"} component={AuthScreen}/>
                        <Route path={"/registerconfirm"} component={AuthScreen}/>
                        <Route path={"/forgotpassword1"} component={AuthScreen}/>
                        <Route path={"/forgotpassword2"} component={AuthScreen}/>
                        <Route component={FullLayout}/>
                    </Switch>
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}