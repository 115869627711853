import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { usePlaidLink } from 'react-plaid-link';
import { PlaidService } from '../../../services/dao/plaid-service';

export default function PlaidConnectButton() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [linkToken, setLinkToken] = useState(null);
    const [institutionType, setInstitutionType] = useState(null);
    
    const plaidService = PlaidService.getInstance();

    useEffect(() => {
        if (institutionType) {
            const getLinkToken = async () => {
                try {
                    const token = await plaidService.createLinkToken();
                    setLinkToken(token);
                } catch (error) {
                    console.error('Error getting link token:', error);
                }
            };
            getLinkToken();
        }
    }, [institutionType]);

    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: async (public_token, metadata) => {
            try {
                await plaidService.exchangePublicToken(
                    public_token,
                    metadata.account_id,
                    institutionType
                );
                // Handle success - maybe refresh the page or show a success message
            } catch (error) {
                console.error('Error exchanging token:', error);
            }
        },
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInstitutionSelect = (type) => {
        setInstitutionType(type);
        handleClose();
        // When ready and we have a link token, open Plaid
        if (ready && linkToken) {
            open();
        }
    };

    return (
        <div>
            <Button
                variant="contained"
                startIcon={<AccountBalanceIcon />}
                onClick={handleClick}
                sx={{ mb: 2 }}
            >
                Connect Your Account
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleInstitutionSelect('bank')}>
                    Bank Account
                </MenuItem>
                <MenuItem onClick={() => handleInstitutionSelect('credit')}>
                    Credit Card
                </MenuItem>
                <MenuItem onClick={() => handleInstitutionSelect('investment')}>
                    Investment Account
                </MenuItem>
            </Menu>
        </div>
    );
}