import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthService } from "./service/auth-service";
import { Hub, Logger } from '@aws-amplify/core';
import { Auth } from "aws-amplify";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Alert, InputAdornment } from '@mui/material';
import { motion } from 'framer-motion';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import { ButtonWithEffects } from '../../components/ui/ButtonWithEffects';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%)',
    },
    image: {
        backgroundImage: "url('images/auth/login.webp')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(45deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 100%)',
            backdropFilter: 'blur(4px)',
        },
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(4),
        height: '100%',
        position: 'relative',
    },
    authBox: {
        width: '100%',
        maxWidth: '450px',
        padding: theme.spacing(6),
        background: 'rgba(255, 255, 255, 0.9)',
        borderRadius: '24px',
        boxShadow: '0 12px 40px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(10px)',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'translateY(-4px)',
        },
    },
    logoContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing(4),
    },
    logo: {
        width: '190px',
        transition: 'transform 0.3s ease',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            transition: 'all 0.3s ease',
            '&:hover': {
                transform: 'translateY(-2px)',
            },
            '& fieldset': {
                borderColor: 'rgba(0, 0, 0, 0.1)',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        padding: theme.spacing(1.5),
        borderRadius: '12px',
        background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
        transition: 'all 0.3s ease',
        '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: `0 6px 20px ${theme.palette.primary.light}40`,
        },
    },
    authLink: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        transition: 'all 0.3s ease',
        '&:hover': {
            color: theme.palette.primary.dark,
            textDecoration: 'underline',
        },
    },
    checkbox: {
        '& .MuiCheckbox-root': {
            borderRadius: '6px',
        },
    },
}));

export function LoginForm() {
    const logger = new Logger('LoginForm');
    const history = useHistory();
    const classes = useStyles();

    const [errorMessage, setErrorMessage] = useState("");
    const [userNotConfirmed, setUserNotConfirmed] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem('savedEmail') || '');
    const [password, setPassword] = useState('');

    useEffect(() => {
        Hub.listen(AuthService.CHANNEL, (data) => {
            onHubCapsule(data);
            logger.info('A new auth event has happened: ', JSON.stringify(data));
        });
        let updateUser = async authState => {
            try {
                let user = await Auth.currentAuthenticatedUser();
                logger.info("I'm logged in");
                history.push("/");
            } catch {
                logger.info("I'm NOT logged in");
            }
        };
        updateUser();

        return function cleanup() {
            logger.info("Removing HUB subscription to " + AuthService.CHANNEL);
            Hub.remove(AuthService.CHANNEL, onHubCapsule);
        };
    }, []);

    // Default handler for listening events
    const onHubCapsule = async (capsule) => {
        const { channel, payload } = capsule;
        logger.info("Hub Payload: " + JSON.stringify(payload));

        if (channel === AuthService.CHANNEL && payload.event === AuthService.AUTH_EVENTS.LOGIN) {
            logger.info("Hub Payload: " + JSON.stringify(payload));
            if (!payload.success) {
                logger.info("Payload error: " + JSON.stringify(payload.error));

                if (payload.error.code === 'UserNotConfirmedException') {
                    setUserNotConfirmed(true);

                    // Resending another code
                    await AuthService.resendConfirmationCode(payload.email);
                } else {
                    setErrorMessage(payload.message);
                    logger.info("Couldn't sign-in: " + payload.message);
                }
            } else {
                history.push("/");
            }
        }
    };
    const onFinish = async e => {
        e.preventDefault();
        await AuthService.login(email, password);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5}>
                <motion.div 
                    className={classes.contentContainer}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                >
                    <div className={classes.authBox}>
                        <div className={classes.logoContainer}>
                            <img 
                                src={window.location.origin + '/logos/logo-md.png'} 
                                className={classes.logo} 
                                alt="Logo" 
                            />
                        </div>
                        <Typography component="h1" variant="h4" align="center" gutterBottom>
                            Welcome Back
                        </Typography>

                        {errorMessage && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                            >
                                <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>
                            </motion.div>
                        )}

                        {userNotConfirmed && (
                            <motion.div
                                initial={{ opacity: 0, y: -10 }}
                                animate={{ opacity: 1, y: 0 }}
                            >
                                <Alert severity="warning" sx={{ mb: 2 }}>
                                    Your username isn't confirmed yet.
                                    A confirmation code was emailed to you. Please <Link to="/registerconfirm" className={classes.authLink}>confirm your email</Link>.
                                </Alert>
                            </motion.div>
                        )}

                        <form className={classes.form} onSubmit={onFinish} noValidate>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                className={classes.textField}
                                value={email}
                                onInput={e => setEmail(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                className={classes.textField}
                                value={password}
                                onInput={e => setPassword(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <FormControlLabel
                                className={classes.checkbox}
                                control={<Checkbox value="remember" color="primary"/>}
                                label="Remember me"
                            />
                            <ButtonWithEffects
                                type="submit"
                                fullWidth
                                variant="contained"
                                className={classes.submit}
                            >
                                Sign In
                            </ButtonWithEffects>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <Link to="/forgotpassword1" className={classes.authLink}>
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to="/register" className={classes.authLink}>
                                        Don't have an account? Sign Up
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </motion.div>
            </Grid>
        </Grid>
    );
}
