import { alpha } from '@mui/material/styles';

export const gradients = {
  primaryGradient: (opacity = 1) =>
    `linear-gradient(45deg, ${alpha('#3B82F6', opacity)} 30%, ${alpha('#60A5FA', opacity)} 90%)`,
  secondaryGradient: (opacity = 1) =>
    `linear-gradient(45deg, ${alpha('#8B5CF6', opacity)} 30%, ${alpha('#A78BFA', opacity)} 90%)`,
  backgroundGradient: 
    'linear-gradient(180deg, #F0F9FF 0%, #E0F2FE 100%)',
  cardGradient: 
    'linear-gradient(145deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.3) 100%)',
  glassEffect: `
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
  `
};

export const shadows = {
  card: '0 8px 32px 0 rgba(31, 38, 135, 0.07)',
  elevated: '0 10px 40px -10px rgba(0, 0, 0, 0.1)',
  subtle: '0 2px 10px rgba(0, 0, 0, 0.05)'
};