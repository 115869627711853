import { Logger } from "@aws-amplify/core";
import { HttpService } from "../http-service";
import { config } from "../constants";

const logger = new Logger("PlaidService");

export class PlaidService {
    static instance;

    static getInstance() {
        if (!PlaidService.instance) {
            PlaidService.instance = new PlaidService();
        }
        return PlaidService.instance;
    }

    constructor() {
        this.http = new HttpService(config.genericService);
    }

    async createLinkToken() {
        try {
            const response = await this.http.makeCall(`${config.genericService}/plaid/create-link-token`, 'POST', null, null, null, true);
            return response.link_token;
        } catch (error) {
            logger.error('Error creating Plaid link token:', error);
            throw error;
        }
    }

    async exchangePublicToken(publicToken, accountId, institutionType) {
        try {
            const response = await this.http.post('/plaid/exchange-public-token', {
                public_token: publicToken,
                account_id: accountId,
                institution_type: institutionType
            });
            return response.data;
        } catch (error) {
            logger.error('Error exchanging public token:', error);
            throw error;
        }
    }
}