import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        minWidth: 275,
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 14,
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 300,
    },
}));

export default function LargestAssetList() {
    const classes = useStyles();
    // TODO: Replace with actual data from API
    const assets = [
        { name: 'Real Estate Property', value: 200000 },
        { name: 'Investment Portfolio', value: 150000 },
        { name: 'Vehicles', value: 45000 },
        { name: 'Art Collection', value: 25000 },
        { name: 'Precious Metals', value: 15000 },
    ];

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Largest Assets
                </Typography>
                <TableContainer>
                    <Table className={classes.table} aria-label="largest assets table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Asset</TableCell>
                                <TableCell align="right">Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assets.map((asset) => (
                                <TableRow key={asset.name}>
                                    <TableCell component="th" scope="row">
                                        {asset.name}
                                    </TableCell>
                                    <TableCell align="right">${asset.value.toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    );
}