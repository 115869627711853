import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        minWidth: 275,
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    value: {
        fontSize: 24,
        fontWeight: 'bold',
    },
}));

export default function TotalHardAssets() {
    const classes = useStyles();
    // TODO: Replace with actual data from API
    const hardAssets = 250000;

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Total Hard Assets
                </Typography>
                <Typography className={classes.value} variant="h5" component="h2">
                    ${hardAssets.toLocaleString()}
                </Typography>
            </CardContent>
        </Card>
    );
}