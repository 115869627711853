import React from 'react';
import { styled } from '@mui/material/styles';
import { fadeIn, slideUp, scaleIn } from './animations';

const AnimationWrapper = styled('div')(({ theme, animation, delay = 0 }) => ({
  animation: `${animation} 0.6s ease-out ${delay}s both`,
  '&.fade-in': {
    animation: `${fadeIn} 0.6s ease-out ${delay}s both`,
  },
  '&.slide-up': {
    animation: `${slideUp} 0.6s ease-out ${delay}s both`,
  },
  '&.scale-in': {
    animation: `${scaleIn} 0.6s ease-out ${delay}s both`,
  },
}));

export const AnimatedComponent = ({ 
  children, 
  animation = 'fade-in',
  delay = 0,
  ...props 
}) => {
  return (
    <AnimationWrapper
      className={animation}
      delay={delay}
      {...props}
    >
      {children}
    </AnimationWrapper>
  );
};