import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { gradients } from './gradients';

const StyledButton = styled(Button)(({ theme }) => ({
  background: gradients.primaryGradient(),
  borderRadius: '8px',
  padding: '10px 24px',
  color: theme.palette.common.white,
  textTransform: 'none',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 8px 20px rgba(59, 130, 246, 0.3)',
    background: gradients.primaryGradient(0.9),
  },
  '&:active': {
    transform: 'translateY(0)',
  },
  '&.secondary': {
    background: gradients.secondaryGradient(),
    '&:hover': {
      background: gradients.secondaryGradient(0.9),
      boxShadow: '0 8px 20px rgba(139, 92, 246, 0.3)',
    },
  }
}));

export const ButtonWithEffects = ({ children, variant = 'primary', ...props }) => {
  return (
    <StyledButton className={variant} {...props}>
      {children}
    </StyledButton>
  );
};