import { styled } from '@mui/material/styles';
import { Card, Button, TextField, Paper, Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

export const DashboardCard = styled(motion(Paper))(({ theme }) => ({
  background: theme.palette.mode === 'dark' 
    ? 'linear-gradient(45deg, #1a237e 30%, #283593 90%)'
    : 'linear-gradient(45deg, #e3f2fd 30%, #bbdefb 90%)',
  borderRadius: '16px',
  padding: theme.spacing(3),
  height: '100%',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: theme.shadows[8],
  }
}));

export const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  color: theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.text.primary,
}));

export const HeaderTitle = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  fontWeight: 600,
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 30px rgba(0, 0, 0, 0.08)',
  },
}));

export const PrimaryButton = styled(Button)(({ theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
  color: theme.palette.common.white,
  boxShadow: '0 3px 15px rgba(25, 118, 210, 0.3)',
  '&:hover': {
    boxShadow: '0 6px 20px rgba(25, 118, 210, 0.4)',
  },
}));

export const SecondaryButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    background: theme.palette.grey[50],
  },
}));

export const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused': {
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}25`,
    },
  },
}));