import React from 'react';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import { gradients, shadows } from './gradients';
import { AnimatedComponent } from './AnimatedComponent';

const StyledCard = styled(Paper)(({ theme }) => ({
  background: gradients.cardGradient,
  boxShadow: shadows.card,
  borderRadius: '16px',
  padding: theme.spacing(3),
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: shadows.elevated,
  }
}));

export const ModernCard = ({ children, animation, delay, ...props }) => {
  return (
    <AnimatedComponent animation={animation} delay={delay}>
      <StyledCard {...props}>
        {children}
      </StyledCard>
    </AnimatedComponent>
  );
};