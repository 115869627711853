import React, {useState} from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from '@mui/icons-material/Close';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import {Link} from 'react-router-dom';
import {Logger} from "@aws-amplify/core";
import {FindocsService} from "../../services/dao/findocs-service";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { makeStyles } from '@mui/styles';

const logger = new Logger("FindocCard");

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
};
const imageModalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    bgcolor: 'rgba(0, 0, 0, 0.9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%)',
    borderRadius: '16px',
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(4),
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(-4px)',
    },
  },
  header: {
    marginBottom: theme.spacing(3),
    color: '#1a237e', // Deep indigo color for headers
  },
  formControl: {
    marginBottom: theme.spacing(2.5),
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  submitButton: {
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    borderRadius: '30px',
    color: 'white',
    padding: '12px 32px',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
    marginTop: theme.spacing(3),
    transition: 'all 0.3s ease',
    '&:hover': {
      background: 'linear-gradient(45deg, #1976D2 30%, #00B4D8 90%)',
      transform: 'scale(1.02)',
    },
  },
  inputField: {
    '& .MuiInputBase-input': {
      padding: '16px',
    },
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
    fontSize: '0.875rem',
  },
}));

const FindocCard = ({findoc}) => {
    const [open, setOpen] = useState(false);
    const [imageOpen, setImageOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const menuOpen = Boolean(anchorEl);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const toggleImageModal = () => setImageOpen(!imageOpen);
    const findocsService = new FindocsService();

    const handleDownloadCSV = () => {
        let tsvContent = "ID\tInstitution Name\tStatement Date\tBalance\tInstitution Address\tDate\tDescription\tAmount\n";

        // Adding receipt metadata
        findoc.lineItems.forEach(lineItem => {
            tsvContent += `${findoc._id}\t${findoc.institutionName}\t${findoc.statementDate}\t${findoc.balance}\t${findoc.institutionAddress}\t${lineItem.transactionDate || ''}\t${lineItem.description || ''}\t${lineItem.amount || ''}\n`;
        });

        const blob = new Blob([tsvContent], {type: 'text/tsv;charset=utf-8;'});
        saveAs(blob, "findoc_details.tsv");
    };

    const imageUrl = `https://cdn.assetsmaven.com/${encodeURIComponent(findoc.imageLocation)}`;

    const formatDate = (dateString) => {
        if (!dateString) return "N/A";
        const date = new Date(dateString);
        return date.toLocaleDateString();
    };

    const renderTable = () => {
        if (matches) {
            return findoc.lineItems.map((lineItem, index) => (
                <Box key={index} sx={{marginBottom: 2, border: '1px solid #e0e0e0', borderRadius: '8px', padding: 2}}>
                    <Typography variant="body1"><strong>Date:</strong> {formatDate(lineItem.transactionDate)}
                    </Typography>
                    <Typography variant="body1"><strong>Category:</strong> <Chip label={lineItem.category} color="primary"
                                                                             variant="outlined"/></Typography>
                    <Typography variant="body1"><strong>Description:</strong> {lineItem.description}</Typography>
                    <Typography variant="body1"><strong>Amount:</strong> {lineItem.amount}</Typography>
                </Box>));
        } else {
            return (<TableContainer component={Paper}>
                    <Table stickyHeader aria-label="line items table" sx={{minWidth: 650, width: '100%'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Date</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell >Description</TableCell>
                                <TableCell align="right">Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {findoc.lineItems && findoc.lineItems.map((lineItem, index) => (<TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {formatDate(lineItem.transactionDate)}
                                    </TableCell>
                                <TableCell align="right"><Chip label={lineItem.category} color="primary" variant="outlined" /></TableCell>
                                    <TableCell >{lineItem.description}</TableCell>
                                    <TableCell align="right">{lineItem.amount}</TableCell>
                                </TableRow>))}
                        </TableBody>
                    </Table>
                </TableContainer>);
        }
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleReanalyze = async () => {
        try {
            let response = await findocsService.reanalyze(findoc._id);
            setAlertMessage("Reanalyze request sent successfully");
        } catch (error) {
            logger.error("Failed to send the reanalyze request", error);
            setAlertMessage("Failed to send the reanalyze request");
        }
        setAlertOpen(true);
        handleMenuClose();
    };

    const handleDelete = async () => {
        try {
            let response = await findocsService.deleteFindoc(findoc._id);
            setAlertMessage("Document deleted successfully");
        } catch (error) {
            logger.error("Failed to delete the document", error);
            setAlertMessage("Failed to delete the document");
        }
        setAlertOpen(true);
        handleMenuClose();
    };

    const handleAlertClose = () => {
        setAlertOpen(false);
    };

    const classes = useStyles();

    return (<Box sx={{display: 'flex', justifyContent: 'center', p: 2, width: '100%'}}>
            <Card className={classes.root}>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={8}>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                                {findoc.institutionName}
                            </Typography>

                        </Grid>
                        <Grid item xs={4} sx={{ textAlign: 'right' }}>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                            >
                                <MoreVertIcon/>
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                open={menuOpen}
                                onClose={handleMenuClose}
                                PaperProps={{
                                    style: {
                                        maxHeight: 48 * 4.5, width: '20ch',
                                    },
                                }}
                            >
                                <MenuItem onClick={handleReanalyze}>
                                    <ReplayIcon sx={{marginRight: 1}}/>
                                    Reanalyze
                                </MenuItem>
                                <MenuItem onClick={handleDelete}>
                                    <DeleteIcon sx={{marginRight: 1}}/>
                                    Delete
                                </MenuItem>
                                <MenuItem onClick={handleDownloadCSV}>
                                    <FileDownloadIcon sx={{marginRight: 1}}/>
                                    Download TSV
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                    <Divider/>

                    <Typography sx={{mb: 1.5, color: '#555'}} color="text.secondary">
                        {formatDate(findoc.statementDate)}
                    </Typography>
                    <Typography variant="overline" display="block" gutterBottom>
                        <Chip icon=<AttachMoneyIcon/> label={findoc.balance} size="small" variant="outlined" />
                    </Typography>
                    <Divider/>

                    <Typography variant="body2" color="text.secondary" sx={{mb: 2}}>
                        {findoc.institutionAddress}
                    </Typography>
                    <Grid item xs={12}>
                        <Link to={"/findocs/" + findoc._id} style={{textDecoration: 'none', width: '100%'}}>

                            <Button
                                startIcon={<ImageSearchIcon/>}
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                style={{marginBottom: 8}}
                            >
                                Details
                            </Button>
                        </Link>

                        <Button
                            startIcon={<ReceiptLongIcon/>}
                            variant="outlined"
                            color="primary"
                            onClick={handleOpen}
                            fullWidth
                        >
                            Quick View
                        </Button>
                    </Grid>

                </CardContent>
            </Card>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{...modalStyle, overflow: 'hidden', maxHeight: '90vh'}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        {findoc.lineItems && <Typography id="modal-modal-title" variant="h6" component="h2">
                            Quick View ({findoc.lineItems.length})
                        </Typography>}
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                    <Box sx={{overflowY: 'auto', maxHeight: '80vh'}}>
                        {renderTable()}
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={imageOpen}
                onClose={toggleImageModal}
                aria-labelledby="image-modal-title"
                aria-describedby="image-modal-description"
            >
                <Box sx={imageModalStyle}>
                    <IconButton
                        onClick={toggleImageModal}
                        color="inherit"
                        sx={{position: 'fixed', top: 8, right: 8, color: 'white'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                    <img src={imageUrl} alt="Findoc" style={{maxHeight: '90%', maxWidth: '90%'}}/>
                </Box>
            </Modal>

            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="success" sx={{width: '100%'}}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Box>);
};

export default FindocCard;
