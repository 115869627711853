import React from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    card: {
        minWidth: 275,
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
    indicatorContainer: {
        marginTop: theme.spacing(2),
    },
    indicator: {
        textAlign: 'center',
        padding: theme.spacing(1),
    },
    indicatorValue: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    positive: {
        color: 'green',
    },
    negative: {
        color: 'red',
    },
}));

export default function MarketConditions() {
    const classes = useStyles();
    // TODO: Replace with actual data from API
    const marketIndicators = {
        sp500: { value: '+1.2%', positive: true },
        interestRate: { value: '4.5%', neutral: true },
        inflation: { value: '-0.5%', positive: true },
        realEstate: { value: '+0.8%', positive: true },
    };

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Current Market Conditions
                </Typography>
                <Grid container spacing={2} className={classes.indicatorContainer}>
                    <Grid item xs={6} sm={3} className={classes.indicator}>
                        <Typography variant="body2" color="textSecondary">
                            S&P 500
                        </Typography>
                        <Typography 
                            className={`${classes.indicatorValue} ${marketIndicators.sp500.positive ? classes.positive : classes.negative}`}
                        >
                            {marketIndicators.sp500.value}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} className={classes.indicator}>
                        <Typography variant="body2" color="textSecondary">
                            Interest Rate
                        </Typography>
                        <Typography className={classes.indicatorValue}>
                            {marketIndicators.interestRate.value}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} className={classes.indicator}>
                        <Typography variant="body2" color="textSecondary">
                            Inflation
                        </Typography>
                        <Typography 
                            className={`${classes.indicatorValue} ${marketIndicators.inflation.positive ? classes.positive : classes.negative}`}
                        >
                            {marketIndicators.inflation.value}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} className={classes.indicator}>
                        <Typography variant="body2" color="textSecondary">
                            Real Estate
                        </Typography>
                        <Typography 
                            className={`${classes.indicatorValue} ${marketIndicators.realEstate.positive ? classes.positive : classes.negative}`}
                        >
                            {marketIndicators.realEstate.value}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}