import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Grid } from "@mui/material";
import { DashboardCard, HeaderWrapper, HeaderTitle } from "../../../components/ui";
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TableChartIcon from '@mui/icons-material/TableChart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import TotalHardAssets from "./findoc-total-hard-assets";
import TotalLiquidAssets from "./findoc-total-liquid-assets";
import AssetGrowth from "./findoc-asset-growth";
import LargestAssetList from "./findoc-largest-assets";
import MarketConditions from "./findoc-market-conditions";



const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.mode === 'dark' ? '#121212' : '#f5f7fa',
    },
    findocsPanel: {
        flexGrow: 1,
        marginLeft: '25%',
        padding: 0,
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            padding: 0,
        },
    },
    
}));

export function DashboardScreen() {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container spacing={3} sx={{ p: 3 }}>
                <Grid item xs={12} md={6} lg={4}>
                    <DashboardCard>
                        <HeaderWrapper>
                            <AccountBalanceWalletIcon />
                            <HeaderTitle variant="h6">Total Hard Assets</HeaderTitle>
                        </HeaderWrapper>
                        <TotalHardAssets />
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DashboardCard>
                        <HeaderWrapper>
                            <MonetizationOnIcon />
                            <HeaderTitle variant="h6">Total Liquid Assets</HeaderTitle>
                        </HeaderWrapper>
                        <TotalLiquidAssets />
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <DashboardCard>
                        <HeaderWrapper>
                            <TrendingUpIcon />
                            <HeaderTitle variant="h6">Asset Growth</HeaderTitle>
                        </HeaderWrapper>
                        <AssetGrowth />
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <DashboardCard>
                        <HeaderWrapper>
                            <ListAltIcon />
                            <HeaderTitle variant="h6">Largest Assets</HeaderTitle>
                        </HeaderWrapper>
                        <LargestAssetList />
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <DashboardCard>
                        <HeaderWrapper>
                            <ShowChartIcon />
                            <HeaderTitle variant="h6">Market Conditions</HeaderTitle>
                        </HeaderWrapper>
                        <MarketConditions />
                    </DashboardCard>
                </Grid>
            </Grid>
        </Box>
    );
}
