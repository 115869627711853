import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';

const useStyles = makeStyles((theme) => ({
    card: {
        minWidth: 275,
        marginBottom: theme.spacing(2),
    },
    title: {
        fontSize: 14,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    positive: {
        color: 'green',
    },
    negative: {
        color: 'red',
    },
    value: {
        fontSize: 24,
        fontWeight: 'bold',
    },
}));

export default function AssetGrowth() {
    const classes = useStyles();
    // TODO: Replace with actual data from API
    const growthRate = 5.2;

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Asset Growth/Decline
                </Typography>
                <Box className={classes.content}>
                    {growthRate >= 0 ? (
                        <TrendingUpIcon className={classes.positive} />
                    ) : (
                        <TrendingDownIcon className={classes.negative} />
                    )}
                    <Typography 
                        className={`${classes.value} ${growthRate >= 0 ? classes.positive : classes.negative}`}
                        variant="h5" 
                        component="h2"
                    >
                        {growthRate}%
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}